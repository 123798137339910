<script>
    import Layout from "../../layouts/main.vue";
    import PageHeader from "@/components/page-header";
    import { ref } from "vue";
    import { useRoute } from "vue-router";
    import BrSuccessErrorMessages, { errorsMessage, successMessage } from "../../components/custom-components/br-success-error-messages.vue";

    export default {
        setup() {
            const route = useRoute();
            const tableName = route.params.tableName;
            const loading = ref(false);
            
            errorsMessage.add({id: errorsMessage.length + 1, message: 'Error.'});
            successMessage.add({id: successMessage.length + 1, message: 'success.'});
            
            return {
                loading,
                title: tableName,
                items: [
                    {
                        text: 'Início',
                        href: '/',
                    },
                    {
                        text: "Listando " + tableName.charAt(0).toUpperCase() + tableName.slice(1),
                        active: true,
                    },
                ],
            }
        },
        components: {
            Layout,
            PageHeader,
            BrSuccessErrorMessages,
        }
    };
</script>
    
<template>
    <BrSuccessErrorMessages />
    
    <Layout>
    <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <p>tabela</p>
                </div>
            </div>
        </div>
    </Layout>
</template>