<script>
import { ref } from 'vue';

const errors = ref([]);
const success = ref([]);

export const errorsMessage = {
    add: (msg) => errors.value.push({message: msg, visible: true}),
    length: errors.value.length,
}
export const successMessage = {
    add: (msg) => success.value.push({message: msg, visible: true}),
    length: success.value.length,
}
export default {
    name: 'BrSuccessErrorMessages',
    setup() {
        return {
            errors,
            success
        }
    },
}
</script>

<template>
    <div class="floating-alert-container col-sm-12 col-md-6 col-lg-4 mt-5 mb-2 px-3 mx-auto fixed-top">
        <div data-simplebar data-simplebar-auto-hide="false" data-simplebar-track="primary">
            <b-alert
                :key="index" 
                v-for="e, index in errors" 
                v-model="e.visible" 
                dismissible
                variant="danger" class="alert-dismissible alert-label-icon label-arrow fade show" role="alert"
            >
                <i class="ri-error-warning-line label-icon"></i>
                {{e.message}}
                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
            </b-alert>

            <b-alert
                v-for="s, index in success"
                :key="index"
                v-model="s.visible" 
                dismissible
                variant="success" class="alert-dismissible alert-label-icon label-arrow fade show" role="alert"
            >
                <i class="ri-check-double-line label-icon"></i>
                {{s.message}}
                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
            </b-alert>
        </div>
    </div>
</template>